import * as React from 'react';
import { BiStore } from 'react-icons/bi';
import { Link as GatsbyLink } from 'gatsby';
import { HamburgerButton } from 'react-hamburger-button';
import Headroom from 'react-headroom';
import {
  Flex,
  Text,
  Box,
  Link,
  BoxProps,
  useTheme,
  PseudoBox,
} from '@chakra-ui/core';
import { FaInstagram, FaGlassCheers } from 'react-icons/fa';
import { Logo } from './Logo';
import { useNavigationContext } from './Navigation.Provider';
import { Button } from './Button';
import { ShopButton } from './ShopButton';

export const NavLink: React.FC<any> = ({ path, ...props }) => (
  <GatsbyLink to={path}>
    <Text
      color="white"
      mx={[4, 8]}
      textTransform="uppercase"
      fontSize={['sm', 'md', 'lg']}
      {...props}
    />
  </GatsbyLink>
);

export const NavBar: React.FC<{ onToggleParty: () => void }> = ({
  onToggleParty,
}) => {
  const { toggleSidebar, isSidebarOpen } = useNavigationContext();
  const theme = useTheme();
  const [state, setState] = React.useState<BoxProps>({
    position: 'absolute',
    transform: 'none',
    bg: ['transparent', 'transparent', 'transparent', 'turquoise'],
  });

  const handleUnpin = () => {
    if (!isSidebarOpen) {
      setState({
        position: 'fixed',
        transform: 'translateY(-100%)',
        bg: ['turquoise'],
      });
    }
  };

  const handlePin = () => {
    if (!isSidebarOpen) {
      setState({
        position: 'fixed',
        transform: 'translateY(0%)',
        bg: ['turquoise'],
      });
    }
  };

  const handleUnfix = () => {
    if (!isSidebarOpen) {
      setState({
        position: 'absolute',
        transform: 'translateY(0%)',
        bg: ['transparent', 'transparent', 'transparent', 'turquoise'],
      });
    }
  };

  return (
    <Headroom
      onUnpin={handleUnpin}
      onPin={handlePin}
      onUnfix={handleUnfix}
      style={{ zIndex: 6 }}
    >
      <Flex
        width={['100%']}
        justifyContent={['center']}
        alignItems={['center']}
        py={5}
        px={[4, 4, 4, 8]}
        transition="0.2s ease-in-out all"
        {...state}
      >
        <Box
          display={['block', 'block', 'block', 'none']}
          position="absolute"
          left={4}
        >
          <PseudoBox
            as="button"
            p={0}
            onClick={onToggleParty}
            bg="transparent"
            _active={{
              bg: 'transparent',
            }}
            _focus={{
              bg: 'transparent',
            }}
          >
            <Box as={FaGlassCheers} color="lavender" size={8} />
          </PseudoBox>
        </Box>
        <NavLink display={['none', 'none', 'none', 'block']} path="#services">
          Services
        </NavLink>
        <NavLink display={['none', 'none', 'none', 'block']} path="#about">
          About
        </NavLink>
        <NavLink display={['none', 'none', 'none', 'block']} path="#contact">
          Contact
        </NavLink>
        <Logo mx={[0, 0, 0, 8]} />
        <NavLink display={['none', 'none', 'none', 'block']} path="#infused">
          Infused
        </NavLink>
        <Button
          onClick={onToggleParty}
          mx={[4, 8]}
          display={['none', 'none', 'none', 'block']}
        >
          IssaParty!
        </Button>
        <Link
          href="https://www.instagram.com/drizzle.catering/"
          display={['none', 'none', 'none', 'initial']}
        >
          <Box as={FaInstagram} color="white" mx={[4, 8]} size={50} />
        </Link>
        <ShopButton />
        <Box
          display={['block', 'block', 'block', 'none']}
          position="absolute"
          right={4}
        >
          <HamburgerButton
            color={theme.colors.coral}
            height={20}
            onClick={toggleSidebar}
            open={isSidebarOpen}
            strokeWidth={3}
            width={35}
          />
        </Box>
      </Flex>
    </Headroom>
  );
};
