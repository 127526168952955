import * as React from 'react';
import ConfettiGenerator from 'confetti-js';
import { Box } from '@chakra-ui/core';
import { useWindowSize } from 'react-use';
import blueZZ from '../images/zz-blue.svg';
import whiteZZ from '../images/zz-white.svg';
import yellowZZ from '../images/zz-yellow.svg';
import pinkZZ from '../images/zz-pink.svg';

interface IssaPartyPropsInterface {
  onToggleParty(): void;
}

export const IssaParty: React.FC<IssaPartyPropsInterface> = ({
  onToggleParty,
}) => {
  const { width } = useWindowSize();
  const [opacity, setOpacity] = React.useState(1);

  React.useEffect(() => {
    const confettiSettings = {
      target: 'issaparty',
      size: width < 1000 ? 1.5 : 3,
      rotate: true,
      start_from_edge: true,
      props: [
        {
          type: 'svg',
          src: blueZZ,
        },
        {
          type: 'svg',
          src: whiteZZ,
        },
        {
          type: 'svg',
          src: yellowZZ,
        },
        {
          type: 'svg',
          src: pinkZZ,
        },
      ],
    };
    const confetti = new ConfettiGenerator(confettiSettings);
    confetti.render();
    setTimeout(() => {
      setOpacity(0);
      setTimeout(() => {
        confetti.clear();
        setOpacity(1);
        onToggleParty();
      }, 500);
    }, 5000);
  }, []);

  return (
    <Box
      opacity={opacity}
      transition="0.5s linear opacity"
      position="fixed"
      width="100vw"
      height="100vh"
      zIndex={50}
      onClick={onToggleParty}
      cursor="pointer"
    >
      <canvas id="issaparty" />
    </Box>
  );
};
