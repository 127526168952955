import { useStaticQuery, graphql } from 'gatsby';
import { Link } from '@chakra-ui/core';
import { BiStore } from 'react-icons/bi';
import * as React from 'react';

export const ShopButton = () => {
  const query = useStaticQuery(graphql`
    query MyQuery {
      allShopifyProduct {
        edges {
          node {
            availableForSale
          }
        }
      }
    }
  `);

  const availableForSale = query.allShopifyProduct.edges.reduce(
    (accum: boolean, item: { node: { availableForSale: boolean } }) => {
      if (!accum) {
        return item.node.availableForSale;
      }
      return accum;
    },
    false,
  );
  console.log('availableForSale', availableForSale);

  return (
    availableForSale && (
      <Link
        display={['none', 'none', 'none', 'block']}
        href="https://wenyerhungry.myshopify.com/products/chili-party"
      >
        <BiStore size={50} />
      </Link>
    )
  );
};
