import React from 'react';
import { ParallaxProvider } from 'react-scroll-parallax';
import { ThemeProvider, CSSReset, Flex } from '@chakra-ui/core';
import { theme } from '../theme';
import { NavBar } from './NavBar';
import { Footer } from './Footer';
import { NavigationProvider } from './Navigation.Provider';
import { MobileSidebar } from './MobileNav';
import { IssaParty } from './IssaParty';

const Layout: React.FC = ({ children }) => {
  const [isItAParty, setIsItAParty] = React.useState<boolean>(false);
  const toggleParty = () => {
    setIsItAParty(s => !s);
  };

  return (
    <ThemeProvider theme={theme}>
      <ParallaxProvider>
        <NavigationProvider>
          <CSSReset />
          <Flex w="100%" minHeight="100vh" bg="red" flexDirection="column">
            <NavBar onToggleParty={toggleParty} />
            <MobileSidebar />
            {isItAParty && <IssaParty onToggleParty={toggleParty} />}
            <Flex flex={1} flexDirection={['column']}>
              {children}
            </Flex>
            <Footer />
          </Flex>
        </NavigationProvider>
      </ParallaxProvider>
    </ThemeProvider>
  );
};

export default Layout;
