import * as React from 'react';
import { Link as GastbyLink } from 'gatsby';
import { fallDown as Menu } from 'react-burger-menu';
import { useTheme, Button, Text, Box, Link, Flex } from '@chakra-ui/core';
import { FaInstagram } from 'react-icons/fa';
import { useNavigationContext } from './Navigation.Provider';

const routes = [
  {
    path: '#about',
    label: 'About',
  },
  {
    path: '#contact',
    label: 'Contact',
  },
  {
    path: '#infused',
    label: 'Infused',
  },
  {
    path: '#services',
    label: 'Services',
  },
];

export const MobileSidebar = () => {
  const theme = useTheme();

  const styles = {
    bmMenuWrap: {
      position: 'fixed',
      marginTop: 0,
      height: '75%',
      zIndex: 5,
    },
    bmMenu: {
      paddingTop: '100px',
      fontSize: '1.15em',
      backgroundColor: theme.colors.turquoise,
    },
    bmItemList: {
      padding: '10px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      flexDirection: 'column',
    },
    bmItem: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      height: 75,
      // marginBottom: 20,
    },
    bmOverlay: {
      background: 'rgba(0, 0, 0, 0.3)',
      marginTop: 0,
      zIndex: 4,
    },
  };

  const { isSidebarOpen, setIsSidebarOpen } = useNavigationContext();

  const handleLinkClick = (e: React.SyntheticEvent) => {
    e.preventDefault();

    setIsSidebarOpen(false);
  };

  return (
    <Menu
      width="100vw"
      styles={styles}
      disableAutoFocus
      right
      isOpen={isSidebarOpen}
      customBurgerIcon={false}
      customCrossIcon={false}
      onStateChange={state => {
        setIsSidebarOpen(state.isOpen);
      }}
    >
      {routes.map(route => (
        <Button
          p="0"
          border="none"
          bg="transparent"
          onClick={handleLinkClick}
          _hover={{ bg: 'transparent' }}
          key={route.label}
        >
          <GastbyLink to={route.path}>
            <Text color="white" fontFamily="body">
              {route.label}
            </Text>
          </GastbyLink>
        </Button>
      ))}
      <Flex height="75px" alignItems={['center']}>
        <Link href="https://www.instagram.com/drizzle.catering/">
          <Box as={FaInstagram} />
        </Link>
      </Flex>
    </Menu>
  );
};
