import { theme as coreTheme } from '@chakra-ui/core';

// Let's say you want to add custom colors
export const theme = {
  ...coreTheme,
  colors: {
    ...coreTheme.colors,
    coral: '#F69A78',
    error: '#F67878',
    turquoise: '#2D94AF',
    pink: '#f3c7e3',
    mint: '#c7f3e3',
    lightBlue: '#D7E2F0',
    navLink: '#0A282F',
    darkBlue: '#031724',
    yellow: '#f5e212',
    lavender: '#ACA7C3',
    inputBg: '#BCB7D2',
    inputBorder: '#918CA8',
    black: '#000000',
  },
  fonts: {
    ...coreTheme.fonts,
    heading: 'Shrikhand',
    body: 'Prompt',
  },
};
