import * as React from 'react';
import { Button as ChakraButton, ButtonProps } from '@chakra-ui/core';

export const Button: React.FC<ButtonProps> = props => {
  return (
    <ChakraButton
      width="auto"
      borderRadius="20px"
      color="white"
      px={8}
      bg="coral"
      textTransform="uppercase"
      fontSize="sm"
      letterSpacing={3}
      {...props}
    />
  );
};
